.botoes-container-painel {
  max-width: 587px;
  margin: 0 auto;
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
}

.botoes-container-painel a {
  width: 50%;
  height: 43px;
  text-align: center;
  align-content: center;
  border-radius: 12px;
}

.p3-container {
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .painel-main h1 {
    text-align: start;
    font-size: 53px;
    line-height: 0.86;
    margin-bottom: 9px;
  }

  .descricao {
    text-align: start !important;
    font-size: 16px;
  }

  .botoes-container-painel {
    margin: 0;
    padding: 0 10px;
  }
}
