.m-divisor-container {
  position: relative;
  width: 100%;
  opacity: 0;
  animation-delay: 0.2s !important;
  animation: 0.55s ease-in opacidade forwards;
}

.m-divisor-linha {
  display: block;
  width: 100%;
  height: 1px;
  background: var(--medgicCorTextoQuartenaria);
  margin-top: 6px;
}

.m-divisor-texto {
  margin: 0;
  position: absolute;
  top: -1px;
  left: 45px;
  transform: translate(45px, -1px);
  background: var(--medgicFundoPrimario);
  padding: 0 12px;
  font-size: 14px;
  color: var(--medgicCorTextoSecundaria);
}

@media (max-width: 768px) {
  .m-divisor-texto {
    top: -2px;
    left: 10px;
    transform: translate(0px, 0px);
    padding: 0 4px;
  }

  .m-bloco-final {
    padding: 20px 0;
  }
}
