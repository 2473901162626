.m-main-links {
  display: flex;
  align-items: center;
  justify-content: center;
}

.m-links-container {
  max-width: 407px;
  padding-top: 10dvh;
}

.m-links-titulo-container p {
  margin: 0;
  font-size: 0.875rem;
  max-width: 50ch;
  color: var(--medgicCorTextoSecundaria);
}

.m-links-titulo-container {
  text-align: center;
}

.m-links-botoes-container {
  margin-top: 20px;
  padding: 10px;
}

.m-links-botao {
  display: flex;
  text-align: center;
  border-radius: 9px;
  overflow: hidden;
  transition: scale 0.05s ease-in-out, box-shadow 0.08s ease-in;
}

.m-links-botao:not(:last-child) {
  margin-bottom: 20px;
}

.m-links-botao:hover {
  scale: 1.01;
  box-shadow: 0px 4px 17px #00000017;
}

.m-links-botao:active {
  scale: 0.99;
  filter: brightness(0.95);
}

.m-links-botao a {
  background-color: var(--medgicCorAcento);
  height: 45px;
  width: 100%;
  text-decoration: none;
  align-content: center;
  color: white;
}

@media (max-width: 768px) {
  .m-links-botao a {
    height: 55px;
  }
}
