.m-aplicativo-main-container {
  display: flex;
  height: calc(100vh - 70px);
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1280px;
  padding: 10px;
}

.m-stores {
  margin-top: 10px;
}

.m-ios-btn {
  display: inline-block;
  overflow: hidden;
  border-radius: 13px;
  width: 250px;
  height: 83px;
}

.m-ios-btn img {
  border-radius: 13px;
  width: 250px;
  height: 83px;
}

@media (max-width: 768px) {
  .m-aplicativo-main-container {
    display: block;
    width: 100%;
    height: unset;
    padding: unset;
  }

  .p1-container {
    padding: 10px;
    display: block;
  }

  .p1-container h1 {
    font-size: 53px;
    line-height: 0.86;
    margin-bottom: 9px;
  }

  .p2-container {
    margin-bottom: 20px !important;
  }
}
