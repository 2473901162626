header {
  position: sticky;
  top: 0;
  text-align: -webkit-center;
  padding: 9px;
  animation-delay: 0.2s;
  animation: 0.4s ease-out opacidadePosicaoMY;
  width: 100%;
  z-index: 1;
  background: #ffffff;
}

.menu-topo {
  display: flex;
  justify-content: space-between;
  max-width: 1536px;
}

.m-link-ativo {
  border-bottom: 2px solid var(--medgicCorAcento) !important;
}

.menu-topo a {
  transition: 0.19s all cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  text-decoration: none;
}

.menu-topo a:hover {
  border-bottom: 2px solid var(--medgicCorAcento);
}

.menu-topo div {
  display: flex;
  gap: 28px;
  align-items: center;
  justify-content: center;
}

.texto-contato:active {
  color: rgb(0 156 255 / 52%);
}

@media (max-width: 768px) {
  header {
    padding: 0;
    border-bottom: 0.8px solid var(--medgicCorTextoQuartenaria);
  }

  .menu-topo {
    padding: 11px;
    width: fit-content;
  }
}
