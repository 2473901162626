strong {
  color: var(--medgicCorAcento);
}

h1 {
  opacity: 0;
  font-size: 96px;
  letter-spacing: 0.1rem;
  line-height: calc(96px * 0.945);
  margin: 0;
  animation: 0.8s ease-out opacidade forwards;
  animation-delay: 0.3s;
}

button {
  outline: 0;
  border: 0;
  font-size: 16px;
  padding: 12px;
  width: 199px;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.12s ease-in-out;
}

.p2-container img {
  opacity: 0;
  animation: 0.6s ease-out forwards opacidadePosicaoX;
  animation-delay: 0.25s;
}

.p3-container img {
  opacity: 0;
  animation: 0.6s ease-out forwards notebook;
  animation-delay: 0.45s;
}

.p1-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.p2-container {
  display: flex;
  height: 100%;
  align-items: center;
}

.descricao {
  opacity: 0;
  margin: 0;
  font-size: 18px;
  max-width: 521px;
  line-height: normal;
  margin-bottom: 50px;
  animation: 0.5s ease-in-out forwards opacidadePosicaoX;
  animation-delay: 0.3s;
}

.painel-main .descricao {
  font-size: max(2.1vmin, 16px);
  max-width: max(35vmax, 60ch);
}

.botoes-container {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
}

.botoes-container a {
  width: 50%;
  height: 43px;
  text-align: center;
  align-content: center;
  border-radius: 12px;
}

.botao-primario {
  opacity: 0;
  background-color: var(--medgicCorAcento);
  color: white;
  font-weight: 500;
  animation: 0.5s ease-in-out forwards opacidadePosicaoMX;
  animation-delay: 0.32s;
}

.botao-outline {
  opacity: 0;
  background-color: transparent;
  box-shadow: inset 0px 0px 0px 2px var(--medgicCorAcento);
  animation: 0.5s ease-in-out forwards opacidadePosicaoMX;
  animation-delay: 0.35s;
  color: var(--medgicCorAcento) !important;
}

.botao-outline * {
  color: var(--medgicCorAcento) !important;
}

.botao-primario:active {
  background-color: rgb(0 124 203 / 82%);
}

p.frase-mobile {
  opacity: 0;
  font-size: 12px;
  color: #000000a1;
  margin: 0;
  animation: 0.3s ease-in-out forwards opacidade;
  animation-delay: 0.4s;
}

.frase-mobile strong {
  color: black;
  font-weight: 400;
}

.botao-outline:active {
  color: rgb(0 156 255 / 65%);
  box-shadow: inset 0px 0px 0px 2px rgb(0 156 255 / 65%);
}

/* Animanção */

@keyframes opacidade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes opacidadePosicaoY {
  0% {
    transform: translateY(25%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes opacidadePosicaoMY {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes opacidadePosicaoMX {
  0% {
    transform: translateX(-25%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes opacidadePosicaoX {
  0% {
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0));
    transform: translateY(-8%);
    opacity: 0;
  }
  100% {
    filter: drop-shadow(10px 13px 29.3px rgba(0, 0, 0, 0.17));
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes notebook {
  0% {
    filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0));
    transform: rotate3d(1, 0, 0, 270deg);
    opacity: 0;
  }
  100% {
    filter: drop-shadow(10px 13px 29.3px rgba(0, 0, 0, 0.17));
    transform: rotate3d(1, 0, 0, 360deg);
    opacity: 1;
  }
}

main.painel-main {
  margin: auto;
  flex-direction: column;
  gap: 10px;
}

.painel-main h1 {
  font-size: max(6.6vmin, 24px);
  text-align: center;
  line-height: 6.8vmin;
}

.painel-main .descricao {
  margin-bottom: 0 !important;
  text-align: center;
  margin: auto;
}

.painel-main div {
  text-align: center;
}

.painel-main .botoes-container {
  justify-content: center;
  position: sticky;
}

@media (max-width: 768px) {
  .p1-container {
    margin-top: 0 !important;
  }

  h1,
  .painel-main h1 {
    font-size: 32px;
    line-height: 38px;
  }

  .descricao {
    max-width: 100% !important;
    width: 100% !important;
    margin-bottom: 17px;
  }

  .p2-container {
    margin-top: 0 !important;
    text-align: center;
    justify-content: center;
  }

  main {
    display: block;
    height: auto;
  }

  main.painel-main {
    display: flex;
    height: calc(100dvh - 60px);
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    padding-bottom: 20px;
  }
}
