.m-main-index {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1280px;
}

.m-bloco {
  text-align: center;
  width: 100%;
}

.m-bloco-primario {
  height: 606px;
  align-content: center;
}

.m-bloco h4 {
  opacity: 0;
  animation-delay: 0.14s !important;
  font-size: 1rem;
  font-weight: lighter;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  animation: 2s ease-out opacidade forwards;
}

.m-bloco img {
  opacity: 0;
  animation-delay: 0.2s;
  animation: 1s ease-in opacidade forwards;
}

.m-bloco h1 {
  font-size: 64px;
  color: var(--medgicCorTextoPrimaria);
}

.m-enfase {
  color: var(--medgicCorAcento);
}

.m-bloco-paragrafo {
  padding: 133px 0;
}

.m-paragrafo-index {
  margin: 0;
  max-width: 65ch;
  float: inline-end;
  text-align: start;
  opacity: 0;
  animation-delay: 0.3s !important;
  animation: 0.6s ease-in-out opacidade forwards;
}

.m-bloco-final {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.m-bloco-final {
  padding: 94px 0;
}

@media (max-width: 768px) {
  .m-main-index {
    padding: 0 10px;
  }

  .m-bloco-primario {
    height: 505px;
  }

  .m-bloco h1 {
    line-height: 1;
  }

  .m-bloco-paragrafo {
    padding: 80px 0;
    height: auto;
  }

  .m-paragrafo-index {
    float: none;
  }

  .m-bloco-final {
    padding: 20px 0;
  }
}
