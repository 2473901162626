:root {
  /* Cores */
  --medgicCorAcento: rgb(0, 156, 255);

  --medgic-erro: rgba(255, 59, 48, 1);
  --medgic-sucesso: rgba(52, 199, 89, 1);

  --medgicFundoPrimario: rgba(249, 249, 249, 1);
  --medgicFundoSecundario: rgba(255, 255, 255, 1);

  --medgicCorTextoPrimaria: rgba(0, 0, 0, 0.85);
  --medgicCorTextoSecundaria: rgba(0, 0, 0, 0.5);
  --medgicCorTextoTerciaria: rgba(0, 0, 0, 0.25);
  --medgicCorTextoQuartenaria: rgba(0, 0, 0, 0.1);

  --medgicBorderRadiusGigante: 22px;
  --medgicBorderRadiusPequeno: 9px;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--medgicFundoPrimario);
}

* {
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', sans-serif;
  color: var(--medgicCorTextoPrimaria);
  user-select: none;
}
