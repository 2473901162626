footer {
  position: sticky !important;
  bottom: 0;
  width: 100%;
  background-color: white;
  z-index: 1;
}

.m-navbar-ios {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background: #ffffffb3;
}

.m-navbar {
  display: flex;
  align-items: center;
  height: 53px;
  border-top: 0.8px solid var(--medgicCorTextoQuartenaria);
}

.m-navbar > div {
  width: 100%;
  text-align: center;
  padding: 0 5px;
  height: 100%;
  align-content: center;
}

.m-navbar-item-link {
  max-width: 8ch;
  word-break: break-word;
  text-align: center;
  margin: auto;
}

.m-navbar a {
  text-decoration: none;
  font-weight: 400;
  font-size: 12px;
}
